import * as React from "react";
import { graphql, navigate } from "gatsby";
import { Button, Typography, makeStyles } from "@material-ui/core";
import Logo from "../images/svg/logo_sport.svg";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    margin: "0 auto",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  root: {
    background: theme.palette.background.white,
    margin: "auto",
    maxWidth: 1440,
    paddingBottom: "1px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,

    gap: "1.736vw",
    "@media(min-width: 1440px)": {
      gap: "25px",
    },
    "@media(max-width: 767px)": {
      gap: "4.83vw",
    },
  },
  logo: {
    width: 115,
    height: 150,
    "@media(max-width: 767px)": {
      width: 76.25,
      height: 100,
    },
  },
  mainText: {
    fontFamily: "'Exo 2'",
    fontWeight: 700,
    textAlign: "center",
    color: theme.palette.color.blue,
    lineHeight: "112.75%",
    fontSize: "4.444vw",
    "@media(min-width: 1440px)": {
      fontSize: "64px",
    },
    "@media(max-width: 767px)": {
      fontSize: "8.695vw",
    },
  },
  bottomText: {
    fontWeight: 400,
    textAlign: "center",
    color: theme.palette.color.lightBlue,
    fontSize: "1.25vw",
    "@media(min-width: 1440px)": {
      fontSize: "18px",
    },
    "@media(max-width: 767px)": {
      fontSize: "3.3816vw",
    },
  },
  button: {
    background: theme.palette.background.orange,
    alignSelf: "center",
    borderRadius: "100px",

    padding: "1.04vw 4.86vw",
    "@media(min-width: 1440px)": {
      padding: "15px 70px",
    },
    "@media(max-width: 767px)": {
      padding: "2.41vw 10.86vw",
    },
  },
  button_text: {
    fontWeight: 700,
    lineHeight: 1.28,
    color: theme.palette.color.white,

    fontSize: "1.66vw",
    "@media(min-width: 1440px)": {
      fontSize: 24,
    },
    "@media(max-width: 767px)": {
      fontSize: "3.86vw",
    },
  },
  whiteRoundedBlock: {
    position: "relative",
    background: theme.palette.background.main,

    height: "11.11vw",
    borderRadius: "0 0 2.43vw 2.43vw",
    marginBottom: "1.736vw",
    "@media(min-width: 1440px)": {
      height: "160px",
      borderRadius: "0 0 35px 35px",
      marginBottom: "25px",
    },
    "@media(max-width: 767px)": {
      height: "7.24vw",
      borderRadius: "0 0 6.03vw 6.03vw",
      marginBottom: "8.091vw",
    },
  },
  footer: {
    "& *": { color: theme.palette.color.white },
    background: theme.palette.background.blue,
  },
  contactsBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 auto",
    maxWidth: 1200,
    paddingTop: 8,
    borderTop: "1px solid #fff",
    width: "83.333%",
    "@media(min-width: 1440px)": {
      width: "1200px",
    },
    "@media(max-width: 767px)": {
      justifyContent: "flex-start",
      width: "92.5%",
    },
  },
  buttonPhone: {
    display: "flex",
    alignItems: "center",

    "@media(max-width: 767px)": {
      order: 1,
      marginLeft: "2.89vw",
    },
  },
  buttonPhone_icon: {
    objectFit: "contain",

    width: "1.66vw",
    height: "1.66vw",
    marginRight: "1.18vw",
    "@media(min-width: 1440px)": {
      width: 24,
      height: 24,
      marginRight: 17,
    },
    "@media(max-width: 767px)": {
      width: "5.79vw",
      height: "5.79vw",
      marginRight: "4.1vw",
    },
  },
  buttonPhone_text: {
    fontWeight: 400,
    lineHeight: 1.28,

    fontSize: "1.66vw",
    "@media(min-width: 1440px)": {
      fontSize: 24,
    },
    "@media(max-width: 767px)": {
      fontSize: "5.79vw",
    },
  },
  socialNetworks_wrapper: {
    display: "flex",
    alignItems: "center",
  },
  buttonSocialNetwork: {
    display: "flex",

    marginRight: "0.83vw",
    "@media(min-width: 1440px)": {
      marginRight: 12,
    },
    "@media(max-width: 767px)": {
      marginRight: "2.89vw",
    },

    "&:last-child": {
      marginRight: 0,
    },
  },
  buttonSocialNetwork_icon: {
    objectFit: "contain",

    width: "1.66vw",
    height: "1.66vw",
    "@media(min-width: 1440px)": {
      width: 24,
      height: 24,
    },
    "@media(max-width: 767px)": {
      width: "5.79vw",
      height: "5.79vw",
    },
  },
}));

const PaymentStatusPage = ({ data }) => {
  const classes = useStyles();
  const general_data = data.prismicContact.data;
  const [success, setSuccess] = useState(null);

  React.useEffect(() => {
    let orderId = localStorage.getItem("order_id") ?? "";
    let retailPaymentId = localStorage.getItem("retail_payment_id") ?? "";
    let yukassaPaymentId = localStorage.getItem("yukassa_payment_id") ?? "";
    setSuccess(false)
    if (orderId !== "" && retailPaymentId !== "" && yukassaPaymentId !== "") {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const body = JSON.stringify({
        yukassaPaymentId: yukassaPaymentId,
        paymentId: retailPaymentId,
        orderId: orderId,
      });
      const init = {
        method: "POST",
        headers,
        body,
      };
      fetch(
        `https://functions.yandexcloud.net/d4ea2qms4b3lf162b86a`,
        init
      ).then((res) =>
        res.json().then((res) => {
          localStorage.setItem("order_id", "");
          localStorage.setItem("retail_payment_id", "");
          localStorage.setItem("yukassa_payment_id", "");
          setSuccess(res.success);
        })
      );
    }
  }, []);

  function goLink(str, options) {
    if (!(str ?? false)) return;

    const anchor = document.createElement("a");
    anchor.href = str;
    if (options) {
      [...Object.keys(options)].forEach((key) => {
        anchor[key] = options[key];
      });
    }
    anchor.click();
  }

  return (
    <>
      <div className={classes.mainWrapper}>
        <div className={classes.root}>
          <Logo className={classes.logo} />
          {success === null ? (
            <>
              <Typography className={classes.mainText}>Загрузка</Typography>
              <Typography className={classes.bottomText}>
                Нужно немного подождать
              </Typography>
            </>
          ) : success === true ? (
            <>
              <Typography className={classes.mainText}>
                Оплата прошла <br />
                успешно!
              </Typography>
              <Typography className={classes.bottomText}>
                В течение 15 минут Вам на почту придет письмо <br />с
                абонементом во вложении. Спасибо за Ваш выбор!
              </Typography>
            </>
          ) : (
            <>
              <Typography className={classes.mainText}>
                Что-то пошло
                <br />
                не так!
              </Typography>
              <Typography className={classes.bottomText}>
                Попробуйте еще раз. В случае повторной ошибки, свяжитесь с{" "}
                <br /> нами по телефону +7 999 333 54 66 для покупки абонемента
              </Typography>
            </>
          )}

          {}

          <Button onClick={(e) => navigate("/")} className={classes.button}>
            <Typography className={classes.button_text}>На главную</Typography>
          </Button>
        </div>
        <div className={classes.footer}>
          <div className={classes.whiteRoundedBlock} />
          <div className={classes.contactsBlock}>
            <button
              onClick={() => goLink(`tel:${general_data.phone_number}`)}
              className={classes.buttonPhone}
            >
              <img
                src={general_data.phone_icon?.localFile?.publicURL}
                alt={general_data.phone_icon?.alt ?? "photo"}
                width={1}
                height={1}
                className={classes.buttonPhone_icon}
              />

              <Typography className={classes.buttonPhone_text}>
                {general_data.phone_number}
              </Typography>
            </button>

            <div className={classes.socialNetworks_wrapper}>
              {general_data.social_networks.map((network, i) => {
                const iconPath = network.network_icon?.localFile?.publicURL;
                const iconAlt = network.network_icon?.alt ?? "social";

                return iconPath ? (
                  <button
                    onClick={() =>
                      goLink(network.network_link, {
                        target: "_blank",
                        rel: "noreferrer",
                      })
                    }
                    aria-label={iconAlt ?? "social"}
                    key={`social_${i}`}
                    className={classes.buttonSocialNetwork}
                  >
                    <img
                      src={iconPath}
                      alt={iconAlt ?? "photo"}
                      width={1}
                      height={1}
                      className={classes.buttonSocialNetwork_icon}
                    />
                  </button>
                ) : null;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * Страница статуса оплаты
 * @module src/page/payment-status
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученный из prismic
 */
export default PaymentStatusPage;

export const query = graphql`
  query PaymentStatusPage {
    prismicContact {
      data {
        phone_number
        phone_icon {
          alt
          localFile {
            publicURL
          }
        }
        social_networks {
          network_link
          network_icon {
            alt
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
